$primary: #1d1b33;
$background: #f2f2f2;
$border: #a1a1a1;

$lightTurqoise: #1abc9c;
$darkTurqoise: #16a085;
$lightGreen: #2ecc71;
$darkGreen: #27ae60;
$lightBlue: #3498db;
$darkBlue: #2980b9;
$lightMagenta: #9b59b6;
$darkMagenta: #8e44ad;
$lightSlate: #34495e;
$darkSlate: #2c3e50;
$lightYellow: #f1c40f;
$darkYellow: #f39c12;
$lightOrange: #e67e22;
$darkOrange: #d35400;
$lightRed: #e74c3c;
$darkRed: #c0392b;
$gray1: #ecf0f1;
$gray2: #bdc3c7;
$gray3: #95a5a6;
$gray4: #7f8c8d;

$controlHeight: 30px;

$fontFamily: "Century Gothic", Lato, "Helvetica Neue", "Arial", sans-serif;

@mixin inputGroup {
    .reset {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $lightRed;
        color: #fff;
        width: $controlHeight;
        border-color: $darkRed !important;
        border-right: none;
        font-size: 15px;

        &:active:focus {
            opacity: 0.6;
        }
    }

    .icon {
        width: $controlHeight;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
    }

    & > * {
        border-top: 1px solid $border !important;
        border-bottom: 1px solid $border !important;
    }

    & > :first-child {
        border-left: 1px solid $border;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    & > :last-child {
        border-right: 1px solid $border;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    & > * + * {
        border-left: 1px solid $border !important;
    }
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: $fontFamily;
    font-size: 14px;
    background-color: $background;
}

input, select, textarea {
    font-family: $fontFamily;
    font-size: 12px;
}

h1 {
    margin: 0 0 20px 0;
}

#outer {
    min-height: 100vh;
    display: flex;
    flex-direction: row;
}

#sidebar {
    background-color: $primary;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;

    .sidebar-title {
        -ms-writing-mode: tb;
        -webkit-writing-mode: tb;
        writing-mode: tb;
        font-weight: bold;
        font-size: 16px;
        transform: scale(-1, -1);

        text-transform: uppercase;
    }
}

#root {
    flex: 1;
    min-height: 100vh;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
}

.hidden {
    visibility: hidden;
}

.page {
    background: #eee;
    flex: 1;
    display: flex;
    flex-direction: row;
}

.panel {
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    //align-items: stretch;
    /*flex: 1;*/
}

.box {
    display: flex;
    flex-direction: column;
    //border: 1px solid $border;
    align-items: stretch;
}

.box-flex-row {
    //border: 1px solid red;
    flex-direction: row;
    //align-items: flex-start;

    & > * + * {
        margin-left: 20px;
    }
}

.box-flex-column {
    //border: 1px solid blue;
    flex-direction: column;
    //align-items: flex-start;
    //align-self: flex-start;
    //justify-content: flex-start;

    & > * + * {
        margin-top: 20px;
    }

    & > .field-wrapper {
        flex: none !important;
    }
}

.flex {
    flex: 1;
}

.row {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.field-wrapper {
    //border: 1px solid $border;
}

.title {
    color: $primary;
    font-weight: bold;
    font-size: 16px;

    display: inline-block;
}

.label {
    color: $primary;
    font-weight: bold;
    font-size: 14px;
}

.placeholder {
    color: $border;
    font-size: 14px;
}

.error {
    color: $lightRed;
    font-weight: bold;
    font-size: 14px;
}

.checkbox {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;

    input[type="checkbox"] {
        border-radius: 5px;
        position: absolute;
        z-index: -1;
        opacity: 0;

        &:checked ~ .indicator {
            background: $primary;
            border: none;

            &::after {
                display: block;
            }
        }

        &:focus ~ .indicator {
            box-shadow: 0 0 0 2pt rgba($primary, 0.1);
        }

        &:active ~ .indicator {
            background: #eee;
        }

        &:disabled ~ .indicator {
            background: #e6e6e6;
            opacity: 0.6;
            pointer-events: none;

            &::after {
                border-color: #7b7b7b;
            }
        }
    }

    &:hover {
        input ~ .indicator {
            background: #f8f8f8;
        }

        input:not([disabled]):checked ~ .indicator {
            background: lighten($primary, 10%);
        }

        input:not([disabled]):checked:active ~ .indicator {
            background: lighten($primary, 20%);
        }
    }

    .indicator {
        border-radius: 5px;
        position: absolute;
        //top: 3px;
        left: 0;
        height: 18px;
        width: 18px;
        background: #fff;
        border: 1px solid #ccc;

        &::after {
            content: '';
            position: absolute;
            display: none;

            left: 6px;
            top: 1px;
            width: 5px;
            height: 10px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }
}

.paginator {
    display: flex;
    justify-content: center;

    button {
        background-color: $primary;
        color: #fff;
        text-decoration: none;
        border: none;
        margin: 0;
        padding: 0;
        width: 32px;
        height: 32px;
        border-radius: 5px;
        font-size: 20px;

        &:active:focus {
            opacity: 0.8;
        }

        &.disabled {
            background-color: #fff;
            color: $primary;
        }
    }

    button + button {
        margin-left: 10px;
    }
}

.segmented-control {
    display: flex;

    button {
        flex: 1;
        border: none;
        margin: 0;
        padding: 0;
        font-weight: bold;
        height: $controlHeight;
        background: #fff;

        border-top: 1px solid $border;
        border-bottom: 1px solid $border;

        &:active:focus {
            opacity: 0.5;
        }

        &.selected {
            background-color: $primary;
            color: #fff;
        }

        &:first-child {
            border-left: 1px solid $border;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        &:last-child {
            border-right: 1px solid $border;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }

    button + button {
        border-left: 1px solid $border;
    }
}

.numeric-segmented-control {
    @extend .segmented-control;

    input {
        flex: 1;
        border: none;
        padding: 0 10px;

        border-top: 1px solid $border;
        border-bottom: 1px solid $border;
        border-right: 1px solid $border;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        &:disabled {
            background-color: #eee;
        }
    }

    button {
        flex: 0;
        padding-left: 15px;
        padding-right: 15px;
    }

    button + input {
        border-left: 1px solid $border;
    }
}

.text-input, .numeric-input {
    display: flex;
    height: $controlHeight;

    @include inputGroup;

    .unit {
        background-color: #eee;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
    }

    input {
        flex: 1;
        border: none;
        padding: 0 10px;

        &:disabled {
            background-color: #eee;
        }
    }
}

.text-area {
    display: flex;
    flex-direction: column;
    flex: 1;

    textarea {
        width: 100%;
        flex: 1;
        padding: 10px;
        border: 1px solid $border;
        border-radius: 5px;
    }
}

.date-picker {
    display: flex;
    height: $controlHeight;

    @include inputGroup;

    .react-datepicker-wrapper, .react-datepicker__input-container {
        width: 100%;
        height: 100%;
    }

    .wrapper {
        flex: 1;
    }

    .picker {
        border: none;
        background: transparent;
        padding: 0 10px;
        width: 100%;
        height: 100%;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        &:disabled {
            background-color: #eee;
        }
    }
}

.dropdown {
    position: relative;

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: inline-block;
        border: 1px solid $border;
        width: 100%;
        height: $controlHeight;
        background: transparent;
        padding: 0 10px;
        border-radius: 5px;

        &::-ms-expand {
            display: none;
        }

        &:disabled {
            background-color: #eee;
        }
    }

    .icon {
        position: absolute;
        right: 0;
        top: 0;
        height: $controlHeight;
        width: $controlHeight;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
    }
}

.button {
    background-color: $primary;
    color: #fff;
    text-decoration: none;
    border: none;
    margin: 0;
    padding: 0;
    height: $controlHeight;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: $controlHeight;

    &:active:focus {
        opacity: 0.8;
    }

    &:disabled {
        background-color: #fff;
        color: $primary;
    }

    .icon {
        font-size: 20px;

        &:first-child {
            padding-left: 10px;
        }

        &:last-child {
            padding-left: 0;
        }
    }

    span {
        font-weight: bold;
        margin: 0 10px;
    }
}

.immo-photo-row {
    display: flex;
    flex-direction: row;
    flex: 1;

    & + & {
        margin-top: 20px;
    }
}

.immo-photo-row-big {
    flex: 2;
}

.immo-empty-photo, .immo-photo {
    border: 1px solid $border;
    border-radius: 5px;
    flex: 1;

    & + & {
        margin-left: 20px;
    }
}

.immo-empty-photo {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background-color: #f8f8f8;
    }

    &:active {
        background-color: #eee;
    }
}

.immo-photo {
    position: relative;

    & > div {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    img {
        position: relative;
        cursor: move;
        flex: 0 1 auto;
        height: 100%;
    }
}

.immo-photo-dragging {
    opacity: 0;
}

.immo-photo-drop-hover {
    background: #eee;
}
